/** SUBSCRIPTION */

export const HeaderWidgetEvt = {
  Visible: 'subsc__header-widget_visible',
  LowCredits: 'subsc__header-widget_low-credits-clicked',
  AddCredits: 'subsc__header-widget_add-credits-clicked',
  PaymentFailed: 'subsc__header-widget_payment-failed-clicked',
  UpdatePayment: 'subsc__header-widget_update-payment-clicked',
  TrailOver: 'subsc__header-widget_trial-over-clicked',
  Upgrade: 'subsc__header-widget_upgrade-plan-clicked',
  TrailOngoing: 'subsc__header-widget_trial-ongoing-clicked',
  BuyPlan: 'subsc__header-widget_buy-plan-clicked',
};

export const ProfileDropdownEvt = {
  Visible: 'subsc__profile-dd_visible',
  EditProfile: 'subsc__profile-dd_edit-profile-clicked',
  OrderQueues: 'subsc__profile-dd_order-queues-clicked',
  TemplateBuilder: 'subsc__profile-dd_template-builder-clicked',
  ManageTeam: 'subsc__profile-dd_manage-team-clicked',
  ManageSubscription: 'subsc__profile-dd_manage-subscription-clicked',
  Settings: 'subsc__profile-dd_settings-clicked',
  Signout: 'subsc__profile-dd_signout-clicked',
  WorkspaceSwitch: 'subsc__profile-dd_workspace_switched',
  WidgetClick: 'subsc__profile-dd_widget-clicked',
};

export const SubscriptionModalEvt = {
  Visible: 'subsc__widget-modal-visible',
  UpgradePlan: 'subsc__widget-modal_upgrade',
  RequestCallback: 'subsc__widget-modal_request_callback',
  UpdatePaymentMethod: 'subsc__widget-modal_update-payment-method',
  ManageAdds: 'subsc__widget-modal_manage-addons',
  UpcomingBillingDetails: 'subsc__widget-modal_see-upcoming-billing-details',
  PlanDetails: 'subsc__widget-modal_plan-details-clicked',
};

export const PayGEvt = {
  Visible: 'subsc__payG_visible',
  CheckoutInitiated: 'subsc__payG_checkout-initiated',
  PaymentProcessing: 'subsc__payG_checkout-processing',
  UpdatePaymentMethod: 'subsc__payG_checkout-new-customer',
  PaymentFailed: 'subsc__payG_checkout-payment-failed',
  PaymentSuccessful: 'subsc__payG_checkout-successful',
  RequestCredits: 'subsc__payG_update-addons',
  UpdateAddons: 'subsc__payG_request-credits',
  LearnAboutPlans: 'subsc__payG_learn-about-plans',
};

export const SubscriptionFallbackEvt = {
  AccessExpired: 'subsc__access-expired_visible',
  UpgradePlan: 'subsc__access-expired_upgrade-clicked',
  ReturnDashboard: 'subsc__access-expired_return-dashboard-clicked',
};

export const TakeoffBreakupEvt = {
  Visible: 'subsc__takeoff-breakup_visible',
};

export const UpdatePaymentMethodModalEvt = {
  Visible: 'subsc__update-payment-method-modal_visible',
  Dismissed: 'subsc__update-payment-method-modal_dismissed',
  UpdateInit: 'subsc__update-payment-method-modal_update-clicked',
  PaymentProcessing: 'subsc__update-payment-method-modal_payment-processing',
  PaymentSuccessful: 'subsc__update-payment-method-modal_payment-successful',
  PaymentAbort: 'subsc__update-payment-method-modal_payment-abort',
};

/** PAGES */

export const SettingsEvt = {
  MyAccount: 'settings__my-account-clicked',
  EditWorkspace: 'settings__edit-workspace-clicked',
  TeamManagement: 'settings__team-management-clicked',
  ManageSubscription: 'settings__manage-subscription-clicked',
  LayerSettings: 'settings__layer-settings-clicked',
  BillingSettings: 'settings__billing-settings-clicked',
  TemplateBuilder: 'settings__billing-template-builder-clicked'
};

/** COMMON */

export const NotificationBarEvt = {
  ReactivateVisible: 'subsc__reactivate_visible',
  ReactivateCtaClicked: 'subsc__reactivate-clicked',
};

/** INSIGHTS DASHBOARD */

export const InsightsDashboardEvt = {
  DashboardTabClicked: 'insights-dashboard__dashboard-tab_clicked',
  SiteInspectionDashboardClicked: 'insights-dashboard__site-inspection-dashboard_clicked',
  CrewAssessmentDashboardClicked: 'insights-dashboard__crew-assessment-dashboard_clicked',
  SiteReconInsightsClicked: 'insights-dashboard__siterecon-insights_clicked',
}

/** NEARMAP OBLIQUE SELECTOR AND MODIFY FLOW */

export const NearmapObliqueEvt = {
  SelectorVisible: 'nearmap-oblique__selector_visible',
  NearmapDateChanged: 'nearmap-oblique__date-changed',
  NearmapViewChanged: 'nearmap-oblique__view-changed',

  ExtendLimitModalVisible: 'nearmap-oblique__extend-nearmap-limit-modal_visible',
  ExtendLimitInitiated: 'nearmap-oblique__extend-nearmap-limit_initiated',
  ExtendLimitFailed: 'nearmap-oblique__extend-nearmap-limit-failed',
  ExtendLimitSuccess: 'nearmap-oblique__extend-nearmap-limit-success',
  ExtendLimitCancelled: 'nearmap-oblique__extend-nearmap-limit-cancelled',

  InsufficientBalanceModalVisible:
    'nearmap-oblique__insufficient-balance-modal_visible',
  InsufficientBalanceModalAddCreditsClicked:
    'nearmap-oblique__insufficient-balance-modal_add-credits-clicked',
  InsufficientBalanceModalCancelled:
    'nearmap-oblique__insufficient-balance-modal_cancelled',

  SwitchToDefaultImageryAlertVisible:
    'nearmap-oblique__switch-to-default-imagery-alert_visible',
  SwitchToDefaultImageryClicked:
    'nearmap-oblique__switch-to-default-imagery_clicked',

  OrderBreakupDetailsPopupVisible:
    'nearmap-oblique__order-breakup-details-popup_visible',

  ModifyParcelInitiated: 'nearmap-oblique__modify-parcel-initiated',
  ModifyParcelSuccess: 'nearmap-oblique__modify-parcel-success',
};

/** MAP CONTROLS */

export const MapControlsEvt = {
  AttributionVisible: 'map-control__attribution_visible',
  MapControllerVisible: 'map-control__map-controller_visible',
  ZoomControlsVisible: 'map-control__zoom-controls_visible',
  MapCompassVisible: 'map-control__map-compass_visible',
  ParcelToggleClicked: 'map-control__parcel-toggle_clicked',
  BaseMapToggleClicked: 'map-control__base-map-toggle_clicked',
  NearmapToggleClicked: 'map-control__nearmap-toggle_clicked',
  SatelliteSelected: 'map-control__satellite-selected',
  StreetSelected: 'map-control__street-selected',
  Rotate90Clicked: 'map-control__rotate-90_clicked',
  ResetToParcelClicked: 'map-control__reset-to-parcel_clicked',
  MapCompassClicked: 'map-control__map-compass_clicked',
}

/** WORKSPACE MAP DASHBOARD */

export const WorkspaceMapDashboardEvt = {
  MapViewClicked: 'wkp-map-db__map-view_clicked',
  ListViewClicked: 'wkp-map-db__list-view_clicked',
  OpenPropertyClicked: 'wkp-map-db__open-property_clicked',
  ClusterClicked: 'wkp-map-db__cluster_clicked',
  PinClicked: 'wkp-map-db__pin_clicked',
  GotoUserLocation: 'wkp-map-db__goto-user-location',
}

/** CREATE ORDER 2.0 */
export const CreateOrderEvt = {
  /** Address search */
  SearchSelectedResult: 'create-order__address-search_selected-result',
  CreateOrderMovedPinLocation: 'create-order-flow__search-moved-pin-location',
  CreateOrderFlowOrderCreate: 'create-order-flow__order-create',
  CreateOrderFlowConfirmAddressInitiated: 'create-order-flow__confirm-address-initiated',
  CreateOrderFlowConfirmAddressFailed: 'create-order-flow__confirm-address-failed',
  CreateOrderFlowSearchSelectedWorkspaceResult: 'create-order-flow__search-selected-workspace-result',
  CreateOrderFlowSearchSelectedResult: 'create-order-flow__search-selected-result',
  /** Parcel review */


  /** Order cart */

  CreateOrderFeaturesConfirm: 'create-order-flow__features-confirm',

  /** Awaiting Approval flow */


  /** Order history */
  OrderHistoryPanelVisible: 'create-order__order-history_panel-visible',
  OrderHistoryStatusWidgetClicked: 'create-order__order-history_status-widget-clicked',
  OrderHistoryRatingSuccess: 'create-order__order-history_rating-success',
  OrderHistoryParcelAttachmentClicked: 'create-order__order-history_parcel-attachment-clicked',
  OrderHistoryCreditsBreakdownClicked: 'create-order__order-history_credits-breakdown-clicked', // plasmic

  AddLayerToMapSuccess: 'create-order__add-layer-to-map_success',

  /** Estimations */
  ServiceItemAssignmentSuccess: 'create-order__service-item_assignment-success',
  ServiceItemChipToggle: 'create-order__service-item_chip-toggle',
  ServiceItemListToggle: 'create-order__service-item_list-toggle',
  EstimationTabClicked: 'create-order__estimations_tab-clicked',
  EstimationTabBookDemo: 'create-order__estimations_book_demo-clicked',
  EstimationTabLearnMore: 'create-order__estimations_learn_more-clicked',

  /** View panel */
  ViewPanelVisible: 'create-order__view-panel_visible', // add source
  ViewPanelViewChange: 'featurepanel__view_change', // legacy
  ViewPanelDeleteView: 'featurepanel__delete_view', // legacy
  ViewPanelRenameView: 'create-order__view-panel_rename-view',
  ViewPanelViewSetAsDefault: 'create-order__view-panel_set-as-default',
  ViewPanelViewPinned: 'create-order__view-panel_view-pinned',
  ViewPanelViewUnpinned: 'create-order__view-panel_view-unpinned',
  ViewPanelNewView: 'featurepanel__new_view', // legacy
  ViewPanelSwitchToLinkedView: 'create-order__switch-to-linked-view', // add source 

  /** Widget bar */
  WidgetBarTabChange: 'create-order__widget-bar_tab-change',
  AddFeedback: 'create-order-flow__feedback-create', // legacy

  /** Action center */
  ActionCenterDragStart: 'create-order__action-center_drag-start',
  ActionCenterDragStop: 'create-order__action-center_drag-stop',
}