import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import OlMap from 'ol/Map';
import pick from 'lodash/pick';
import { IStore } from '@store/types';
import { setOrderLayers } from '@store/order/actions';

/** Types */
import { NumericId, ViewType } from '../../../common/types';
import {
  ApiTopologyWarning,
  EstimationLayerType,
  LayerWithComponents,
  SingleComponent,
} from '../../api/types';

/** Custom hooks */
import { useLayerRender } from './useLayerRender';
import useLayerHover from './useLayerHover';
import useLayerSelect from './useLayerSelect';
import useLayerHighlight, {
  UseLayerHighlightOptions,
} from './useLayerHighlight';
import useViewUtility from '../view/useViewUtility';
import useCurrentView from '../../../../jotai/atoms/views/useCurrentView';

/** Helpers */
import { queryClient } from '../../../common/libs/query-client';
import { topologyWarningsAtom } from '../../../../jotai/atoms/property/takeoff/layer-panel';
import { getLayerComponents, getLayers } from '../../api';
import { getLayerComponent } from '../../helpers/layer-render.helpers';
import MapContext from '@components/pages/project/MapContext';
import { getLayerUnit } from '../../../../helpers/utils';
import { syncCustomDeletedStyle } from '../feature/useFetchFeatureStyleCatalogue';
import { useFeatureStyle } from '../../../../jotai/atoms/features';


export enum UseLayerSource {
  TAKEOFF = 'takeoff',
  ESTIMATION = 'estimation',
}

interface UseLayerOptions extends Partial<UseLayerHighlightOptions> {
  source?: UseLayerSource;
  enabled?: boolean;
}

type TopologyWarningOverlapItem = Pick<
  SingleComponent,
  'componentId' | 'componentName' | 'layerId' | 'featureType'
>;

type Warning = Record<NumericId, string[]>;

interface TopologyWarning {
  overlapping: Warning;
  self_intersecting: Warning;
  null_geometry: Warning;
  duplicate: Warning;
}

const useLayers = (viewId: NumericId | null, options?: UseLayerOptions) => {
  const mapRef: OlMap = useContext(MapContext);
  const source = options?.source ?? UseLayerSource.TAKEOFF;
  const dispatch = useDispatch();
  const { currentView } = useCurrentView();
  const layers = useSelector<IStore, LayerWithComponents[]>(
    (state) => state.order.layerList
  );

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const {
    addLayer,
    handleToggleLayer: toggleLayer,
    handleToggleComponent: toggleComponent,
  } = useLayerRender();

  useLayerHover(mapRef, source);
  useLayerHighlight(
    mapRef,
    options ? pick(options, ['onHighlightChange', 'source']) : undefined
  );
  const {
    handleFeatureHighlight,
    handleLayerHighlight,
    handleAllFeatureUnHighlight,
  } = useLayerSelect(mapRef);

  const { getViewByViewId } = useViewUtility();

  const [, setTopologyWarnings] = useAtom(topologyWarningsAtom);
  const [catalogue, setFeatureStyleCatalogue] = useFeatureStyle();
  const [initalCall,setInitalCall]=useState(false)

  useEffect(()=>{
    if(catalogue.length && layers.length){
      syncCustomDeletedStyle(catalogue,layers,setFeatureStyleCatalogue)
    }
  },[catalogue.length,layers.length])

  useEffect(() => {
    if (
      viewId &&
      currentView &&
      currentView.viewId !== viewId &&
      currentView.linkedView !== viewId
    ) {
      /** When `viewId` is changed, we need to invalidate cached date for previous layers and viewId  */
      queryClient.invalidateQueries({ queryKey: ['getLayers'] });
    }
  }, [viewId]);

  useQuery({
    /** We have added a logic to invalid the layers and components data whenever parentId is changed in FeaturePanel */
    queryKey: ['getLayers', viewId],
    staleTime: 60 * 60 * 1000,
    cacheTime: 0,
    queryFn: () => {
      if (
        (options?.source === UseLayerSource.ESTIMATION &&
          options.enabled &&
          currentView.viewType !== ViewType.STATIC) ||
        (options?.source !== UseLayerSource.ESTIMATION && !!viewId)
      ) {
        setIsFetching(true);
        return getLayers(viewId!).then((response) => response.data);
      }
      return undefined;
    },
    enabled:
      options?.source === UseLayerSource.ESTIMATION
        ? options.enabled && currentView.viewType !== ViewType.STATIC
        : !!viewId,
    onSuccess: async (data) => {
      setIsFetching(true);
      const tempLayers: LayerWithComponents[] = [];
      let topologyWarnings: ApiTopologyWarning = {
        count: 0,
        kink: [],
        overlap: [],
        duplicate: [],
        nullGeometry: [],
      };

      const getLayerComponentRequests: Promise<LayerWithComponents>[] = [];

      for (const layer of data) {
        const getLayerComponentRequest = getLayerComponents(
          getViewByViewId(viewId!),
          layer.layerId as NumericId
        ).then(({ data, topologyWarning: layerTopologyWarnings }) => {
          const layerWithComponents: LayerWithComponents = {
            ...layer,
            type: EstimationLayerType.TAKEOFF,
            components: data.map((component, index) => {
              const unit = getLayerUnit(layer.featureType);

              return {
                ...component,
                componentName:
                  (currentView.viewType !== ViewType.STATIC
                    ? `${layer.name} `
                    : '') + `ID${index + 1}`,
                name: layer.name,
                layerName: layer.name,
                unit: unit,
                featureType: component.featureType ?? layer.featureType,
                featureId: component.featureId ?? layer.featureId,
                layerId: layer.layerId as NumericId,
              };
            }),
          };

          addLayer(layerWithComponents);

          if (layerTopologyWarnings) {
            for (const key of Object.keys(topologyWarnings)) {
              if (key === 'count') {
                topologyWarnings[key] += layerTopologyWarnings.count ?? 0;
                continue;
              }
              topologyWarnings[key].push(...(layerTopologyWarnings[key] ?? []));
            }
          }

          tempLayers.push(layerWithComponents);
          dispatch(setOrderLayers(tempLayers))
          return layerWithComponents;
        });

        getLayerComponentRequests.push(getLayerComponentRequest);
      }

      try {
        const layerComponentResponses = await Promise.all(
          getLayerComponentRequests
        );

        const transformedWarnings = transformTopologyWarnings(
          topologyWarnings,
          layerComponentResponses
        );

        setTopologyWarnings(transformedWarnings);
        dispatch(setOrderLayers(layerComponentResponses))
        
      } catch (error) {
        console.error(
          'Error Occurred while fetching layer component details',
          error
        );
      } finally {
        setIsFetching(false);
        setInitalCall(true)
      }
    },
  });

  const handleToggleLayer = (checked: boolean, layerId: NumericId) => {
    const layer = layers.find((_layer) => _layer.layerId === layerId);
    if (!layer) return;

    toggleLayer(checked, layer);
  };

  const handleToggleComponent = (
    checked: boolean,
    layerId: NumericId,
    componentId: NumericId
  ) => {
    const component = getLayerComponent(layers, layerId, componentId);
    if (!component) return;

    toggleComponent(checked, layerId, component);
  };

  return {
    layers,
    isFetching,
    handleToggleLayer,
    handleToggleComponent,
    handleFeatureHighlight,
    handleLayerHighlight,
    handleAllFeatureUnHighlight,
  };
};

export const transformTopologyWarnings = (
  data: ApiTopologyWarning,
  layers: LayerWithComponents[]
): TopologyWarning => {
  const componentsMap: Record<NumericId, SingleComponent> = {};

  for (const layer of layers) {
    for (const component of layer.components) {
      componentsMap[component.componentId] = component;
    }
  }

  const warnings: TopologyWarning = {
    overlapping: {},
    self_intersecting: {},
    null_geometry: {},
    duplicate: {},
  };

  /** Map component IDs to their names */
  const mapComponentIdsToNames = (componentIds: NumericId[]) =>
    componentIds
      .map((componentId) => {
        const component: SingleComponent | undefined =
          componentsMap[componentId];
        return component ? component.componentName : null;
      })
      .filter((component) => !!component) as string[];

  if (data.overlap) {
    for (const item of data.overlap) {
      warnings.overlapping[item.primaryGeometry] = mapComponentIdsToNames(
        item.overlapGeometry
      );
    }
  }

  if (data.kink) {
    for (const componentId of data.kink) {
      warnings.self_intersecting[componentId] = mapComponentIdsToNames([
        componentId,
      ]);
    }
  }

  if (data.nullGeometry) {
    for (const componentId of data.nullGeometry) {
      warnings.null_geometry[componentId] = mapComponentIdsToNames([
        componentId,
      ]);
    }
  }

  if (data.duplicate) {
    for (const componentId of data.duplicate) {
      warnings.duplicate[componentId] = mapComponentIdsToNames([componentId]);
    }
  }

  return warnings;
};

const getComponentTopologyWarnings = (
  data: TopologyWarning,
  componentId: NumericId
): string[] => {
  const warnings: string[] = [];

  const overlappingFeatureTypeGroupedComponents: Record<string, string[]> = {};

  if (data.overlapping[componentId]) {
    for (const overlappedComponent of data.overlapping[componentId]) {
      const featureType = overlappedComponent.featureType ?? 'undefined';
      if (!overlappingFeatureTypeGroupedComponents[featureType]) {
        overlappingFeatureTypeGroupedComponents[featureType] = [];
      }

      overlappingFeatureTypeGroupedComponents[featureType].push(
        overlappedComponent.componentName ?? ''
      );
    }
  }

  if (Object.keys(overlappingFeatureTypeGroupedComponents).length > 0) {
    let overlapWarning = 'Overlapping ';
    const groupedWarnings: string[] = [];

    for (const [featureType, componentNames] of Object.entries(
      overlappingFeatureTypeGroupedComponents
    )) {
      groupedWarnings.push(
        featureType === 'undefined'
          ? componentNames.join(',')
          : `${featureType} ${componentNames.join(',')}`
      );
    }

    overlapWarning += groupedWarnings.join(', ');

    warnings.push(overlapWarning);
  }

  return warnings;
};

export default useLayers;
