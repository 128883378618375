/** PAGES */
export const AUTH_PAGE = '/auth';
export const SIGNUP_PAGE = '/signup';
export const WORKSPACE_LIST_PAGE = '/workspace';

export const PATH_WITH_NO_WORKSPACE_ID = [
  WORKSPACE_LIST_PAGE,
  AUTH_PAGE,
  SIGNUP_PAGE,
];

/** FLUTTER FLOW PAGES */
export const SUBSCRIPTION_HOME_URL = '/billing/subscriptionHome';
export const TEMPLATE_BUILDER_URL = '/template-builder';
export const SUBSCRIPTION_MANAGEMENT_URL = '/billing/getSubscription';
export const TEAM_MANAGEMENT_URL = '/billing/teamManagement';
export const SUBSCRIPTION_UPDATE_CREDITS = '/billing/updateCredits'
export const SUBSCRIPTION_BILLING_HISTORY = '/billing/billingHistory'