import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFeatureStyleCatalogue, getStyleForFeature } from '../../api';
import { useFeatureStyle } from '../../../../jotai/atoms/features';
import { FeatureStyleCatalogueMap } from '../../types/layer.types';



const findMissingFeatureIds = (OrderLayers, styleList) => {
  const styleFeatureIds = styleList.map(style => style.featureId);
  return OrderLayers
    .filter(layer => !styleFeatureIds.includes(layer.featureId))
    .map(layer => layer.featureId);
};


export const syncCustomDeletedStyle = async (catalogue, OrderLayers, setFeatureStyleCatalogue) => {
  return new Promise(async (resolve, reject) => {
    try {
      const missingStyleFeatureIds = findMissingFeatureIds(OrderLayers, catalogue); // make an API call to get missing ids style data

      if (!missingStyleFeatureIds.length) {
        resolve(null); // Resolve with null if no missing IDs
        return;
      }

      // Fetch style data for missing feature IDs
      const styleData = await getStyleForFeature(missingStyleFeatureIds);

      if (styleData) {
        setFeatureStyleCatalogue((prev) => [...prev, ...styleData.data]);
        const updatedCatalogue = [...catalogue, ...styleData.data];
        resolve(updatedCatalogue); // Resolve with the updated catalogue
      } else {
        console.log('No style data available or an error occurred.');
        resolve(catalogue); // Resolve with the original catalogue if no data
      }
    } catch (error) {
      console.error('Error fetching style data:', error);
      reject(error); // Reject the promise with the error
    }
  });
};


const useFetchFeatureStyleCatalogue = () => {
  const [catalogue, setFeatureStyleCatalogue] = useFeatureStyle();

  const { data, refetch } = useQuery(
    ['getFeatureStyleCatalogue'],
    getFeatureStyleCatalogue
  );

  const featureStyleCatalogueMap: FeatureStyleCatalogueMap = useMemo(() => {
    const map = {};

    if (catalogue) {
      catalogue.forEach((featureStyle) => {
        map[featureStyle.featureId] = featureStyle.styles.sort((a, b) => {
          if (!a.styleId && b.styleId) {
            return -1;
          }
          if (a.styleId && !b.styleId) {
            return 1;
          }
          if (a.styleId && b.styleId) {
            return a.styleId - b.styleId;
          }
          return -1;
        });
      });
    }
    return map;
  }, [catalogue]);

  useEffect(() => {
    if (data) {
      setFeatureStyleCatalogue(data);
    }
  }, [data, setFeatureStyleCatalogue]);

  return { refetch, data, featureStyleCatalogueMap };
};

export default useFetchFeatureStyleCatalogue;
