import { DragBox } from 'ol/interaction';
import { GeoJSON } from 'ol/format';
import * as turf from '@turf/turf';
import { message } from 'antd';
import { platformModifierKeyOnly } from 'ol/events/condition';
import {
  hoveredFeatureStyle,
  pointHoverIconFeature,
  pointSelectedTempStyle,
  selectedFeatureStyle,
  updatePathStyleFunction,
} from '../../mapGlobals/styles';
import Feature from 'ol/Feature';
import { Polygon } from 'ol/geom';

const LINE = 'LineString';
const POINT = 'Point';
let dragBoxInteraction;
export const multiSelectTool = (
  /* ol/Map: Map instance reference */
  mapRef,
  /* ol/Layer/VectorLayer: VectorLayer Instance */
  olVectorLayer,
  /* JSONObject: {"id": 227120, "name": "Turf"} */
  viewLayer,
  /* Function: Method with arguments - (component, selectedLayer) */
  multiSelectCallBack,
  /**/
  layer,
  featureType
) => {
  if (dragBoxInteraction) {
    mapRef.removeInteraction(dragBoxInteraction);
  }

  dragBoxInteraction = new DragBox({
    condition: platformModifierKeyOnly,
    className: 'multiSelectBox',
  });
  mapRef.addInteraction(dragBoxInteraction);
  dragBoxInteraction.setActive(true);
  onDrawEnd(
    dragBoxInteraction,
    mapRef,
    olVectorLayer,
    viewLayer,
    multiSelectCallBack,
    layer,
    featureType
  );
};

const onDrawEnd = (
  /* ol/Interaction: Map interaction reference */
  virtualBox,
  /* ol/Map: Map instance reference */
  mapRef,
  /* ol/Layer/VectorLayer: VectorLayer Instance */
  olVectorLayer,
  /* JSONObject: {"id": 227120, "name": "Turf"} */
  viewLayer,
  /* Function: Method with arguments - fn(component, selectedLayer) */
  callback,
  layer,
  featureType
) => {
  virtualBox.on('boxend', (event) => {
    if (!olVectorLayer) {
      /** OL Vector source is not available from props. So, we need to find it directly from map */
      mapRef.getLayers().forEach((layer) => {
        const layerId = layer.getProperties?.().id;

        if (
          layerId &&
          Number(layerId) === Number(viewLayer.id) &&
          layer?.getSource()
        ) {
          olVectorLayer = layer;
        }
      });
    }

    if (!olVectorLayer) {
      /** We don't have source layer, So we cant' continue with multi-select */
      return;
    }

    let olVectorSource = olVectorLayer?.getSource();
    // If there are no geometries inside the virtual rectangle, do nothing
    if (!olVectorSource?.getFeatures()?.length) {
      return;
    }

    // Fetch the virtual box drawn
    let drawnPolygon = virtualRectangle(virtualBox);

    // Store the geometries where virtual box and layer items intersect
    let olFeatureObjectSelected = [];

    // iterate through every feature in that vector layer and
    // see if it intersects the virtual box
    olVectorSource?.forEachFeature((feature) => {
      let layerComponent = rawFeatureToJsonFeature(feature);
      const layerComponentGeometry = geometryType(feature);
      try {
        if (
          hasIntersection(layerComponent, drawnPolygon, layerComponentGeometry)
        ) {
          olFeatureObjectSelected.push(feature);
        }
      } catch (error) {
        console.error([error, error.name, feature]);
        message.error(error.name);
      }
    });

    // Invoke the callback with selected components in the view layer
    if (callback) {
      callback(convertToComponentList(olFeatureObjectSelected), viewLayer);
    }
    // style the layer entities as selected
    styleSelectedOlFeatures(
      olFeatureObjectSelected,
      olVectorSource,
      olVectorLayer,
      layer,
      featureType
    );
  });
};

/**
 * Create a rectangle object from virtualBox on box draw end
 * @param virtualBox
 * @returns {any}
 */
export const virtualRectangle = (virtualBox) => {
  const geometry = virtualBox.getGeometry();
  
  // Get the transformed coordinates directly from the geometry
  const coordinates = geometry.getCoordinates();

  // Create a polygon with the transformed coordinates
  let polygon = new Polygon(coordinates);
  // Transform to geographic coordinates
  polygon.transform('EPSG:3857', 'EPSG:4326');

  return new GeoJSON().writeGeometryObject(polygon);
};

/**
 *
 * @param feature
 * @returns {any}
 */
export const rawFeatureToJsonFeature = (
  /* ol/Feature */
  feature
) => {
  let geojson_geom = new GeoJSON();
  let properties = { ...feature.getProperties() };
  delete properties.geometry;
  let geom = feature['values_']['geometry'].clone();
  let coord = geojson_geom.writeGeometry(
    geom.transform('EPSG:3857', 'EPSG:4326')
  );
  return JSON.parse(coord);
};

/**
 *
 * @param feature
 * @returns {*}
 */
export const geometryType = (feature) => {
  return feature.getGeometry().getType();
};

/**
 *
 * @param olFeatureObjectSelected
 * @param olVectorSource
 */
export const styleSelectedOlFeatures = (
  /* */
  olFeatureObjectSelected,
  /* */
  olVectorSource,
  /* */
  olVectorLayer,
  layer,
  featureType
) => {
  olFeatureObjectSelected.forEach((feature) => {
    olVectorSource.removeFeature(feature);
    if (feature.getGeometry().getType() === 'Point') {
      if (layer.style) {
        feature.setStyle(pointHoverIconFeature(layer.style));
      } else {
        // Keeping this as backup just in case there is a null
        feature.setStyle(pointSelectedTempStyle());
      }
    } else if (featureType === 'path') {
      let styleToBeApplied = {
        ...layer.style,
        color: hoveredFeatureStyle.getStroke().getColor(),
        fillColor: hoveredFeatureStyle.getFill().getColor(),
        arrowColor: hoveredFeatureStyle.getStroke().getColor(),
      };
      updatePathStyleFunction([feature], styleToBeApplied);
    } else {
      feature.setStyle(selectedFeatureStyle());
    }
    olVectorSource.addFeature(feature);
  });
};

export const convertFeatureToComponent = (feature) => {
  let featureJson = new GeoJSON();
  featureJson = JSON.parse(featureJson.writeFeature(feature));

  return { ...featureJson, componentId: featureJson.id };
};

/**
 *
 * @param olFeatureObjectSelected
 * @returns {*[]}
 */
export const convertToComponentList = (olFeatureObjectSelected) => {
  let componentIdSelected = [];
  olFeatureObjectSelected.forEach((item) => {
    componentIdSelected.push(convertFeatureToComponent(item));
  });
  return componentIdSelected;
};

/**
 *
 * @param layerComponent
 * @param drawnPolygon
 * @param layerComponentGeometry
 * @returns {boolean}
 */
export const hasIntersection = (
  layerComponent,
  drawnPolygon,
  layerComponentGeometry
) => {
  // If layer component = polygon
  if (layerComponentGeometry !== POINT && layerComponentGeometry !== LINE) {
    if (
      turf.booleanOverlap(layerComponent, drawnPolygon) ||
      turf.booleanContains(drawnPolygon, layerComponent) ||
      turf.booleanContains(layerComponent, drawnPolygon)
    ) {
      return true;
    }
  }
  // If layer component = line
  else if (layerComponentGeometry === LINE) {
    if (
      turf.booleanContains(drawnPolygon, layerComponent) ||
      turf.booleanIntersects(layerComponent, drawnPolygon)
    ) {
      return true;
    }
  }
  // If layer component = point
  else if (layerComponentGeometry === POINT) {
    if (turf.booleanPointInPolygon(layerComponent, drawnPolygon)) {
      return true;
    }
  }
  return false;
};

export const removeDragBoxInteraction = (mapRef) => {
  if (dragBoxInteraction) {
    mapRef.getInteractions().forEach((interaction) => {
      if (interaction === dragBoxInteraction) {
        mapRef.removeInteraction(dragBoxInteraction);
      }
    });
  }
};
