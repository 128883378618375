import { useEffect, useState } from 'react';
import { useFeatureStyle } from '../../../../jotai/atoms/features';
import { NumericId } from '../../../common/types';
import { addFeatureStyleToCatalogue } from '../../api';
import {
  FeatureStyle,
  FeatureStyleCatalogueResponse,
  LayerStyle,
} from '../../types/layer.types';
import { syncCustomDeletedStyle } from './useFetchFeatureStyleCatalogue';
import { useSelector } from 'react-redux';

const useAddFeatureStyle = () => {
  const [, setFeatureStyleCatalogue] = useFeatureStyle();
  const layers = useSelector((state) => state.order.layerList);

  // Helper function to sort and update styles
  const sortAndMarkStyles = (styles: FeatureStyle[]): FeatureStyle[] => {
    const sortedStyles = styles.sort((a, b) => {
      if (!a.styleId && b.styleId) return -1;
      if (a.styleId && !b.styleId) return 1;
      if (a.styleId && b.styleId) return a.styleId - b.styleId;
      return -1;
    });

    if (sortedStyles.length > 0) {
      sortedStyles[sortedStyles.length - 1].isEditing = true;
    }

    return sortedStyles;
  };

  // Helper function to process features
  const processFeatures = (
    features: FeatureStyleCatalogueResponse,
    featureId: NumericId
  ): { updatedFeatures: FeatureStyleCatalogueResponse; style: FeatureStyle | null } => {
    let style: FeatureStyle | null = null;

    const updatedFeatures = features.map((feature) => {
      if (feature.featureId === featureId) {
        const updatedStyles = sortAndMarkStyles(feature.styles);
        style = updatedStyles[updatedStyles.length - 1] || null;
        return { ...feature, styles: updatedStyles };
      }
      return feature;
    });

    return { updatedFeatures, style };
  };

  const addStyle = async (featureId: NumericId, styleObj: LayerStyle) => {
    try {
      const response: FeatureStyleCatalogueResponse =
        await addFeatureStyleToCatalogue(featureId, styleObj);

      const foundItem = response.find((el) => el.featureId === featureId);

      if (!foundItem) {
        // Sync deleted styles if feature is not found
        const syncedDeletedStyles = await syncCustomDeletedStyle(
          response,
          layers,
          setFeatureStyleCatalogue
        );
        const { updatedFeatures, style } = processFeatures(syncedDeletedStyles, featureId);
        setTimeout(() => setFeatureStyleCatalogue(updatedFeatures), 100);
        return style;
      }
      // Process existing feature styles
      const { updatedFeatures, style } = processFeatures(response, featureId);

      setTimeout(() => setFeatureStyleCatalogue(updatedFeatures), 100);

      return style;
     
    } catch (error) {
      console.error('Error adding feature style:', error);
      return null;
    }
  };

  return { addStyle };
};

export default useAddFeatureStyle;
