import { OrderStatus } from '@/modules/common/types';
import { BULK_STATUS_CONST } from './pages/myProperties/utils/enum';
import { getBottomLeft, getBottomRight, getTopLeft, getTopRight } from 'ol/extent';
import { differenceInDays } from 'date-fns';
import { SHARE_MAP_PAGE } from './constants';

export const ORDER_STATUS_WITH_NOTES_ALLOWED_ON_MAP_PAGE = [
  OrderStatus.Draft,
  OrderStatus.MeasurementOngoing,
  OrderStatus.AwaitingApproval,
  OrderStatus.Approved,
  OrderStatus.ResolvingFeedback,
  OrderStatus.Archived,
  OrderStatus.SelfMeasured,
  OrderStatus.BoltPreview,
  OrderStatus.BoltApproved,
  OrderStatus.BoltInVerification
];

export const BULK_ORDER_STATUS_WITH_NOTES_ALLOWED_ON_MAP_PAGE = [
  BULK_STATUS_CONST.Created,
  BULK_STATUS_CONST.Parcel_Review,
  BULK_STATUS_CONST.In_Progress
];

export const ORDER_STATUS_ALLOWED_FOR_ORDER_FLOW_PAGE = [
  OrderStatus.AwaitingApproval,
  OrderStatus.Approved,
  OrderStatus.ResolvingFeedback,
  OrderStatus.SelfMeasured
];

export const ORDER_STATUS_ALLOWED_FOR_GEOJSON_UPLOAD = [
  OrderStatus.Approved,
  OrderStatus.SelfMeasured,
  OrderStatus.BoltPreview,
  OrderStatus.BoltApproved,
  OrderStatus.BoltInVerification,
  OrderStatus.Archived
];

export const ORDER_STATUES_NOT_ALLOWED_FOR_GEOJSON_UPLOAD = [
  OrderStatus.Draft,
  OrderStatus.MeasurementOngoing,
];

export const ORDER_STATUS_ALLOWED_FOR_TABULA_PLACEMENT = [
  OrderStatus.MeasurementOngoing,
  OrderStatus.SelfMeasured,
  OrderStatus.BoltPreview,
  OrderStatus.BoltApproved
];


export const getInitials = (name) => {
  if (name) {
    return name
      .split(' ')
      .map((n) => n[0]?.toUpperCase())
      .join('');
  }
  return '';
};

export const fetchFirst2char = (str) => {
  if (str) {
    return str.substring(0, 2);
  }
  return '';
};

export const isNotesAllowedGivenOrderStatus = (orderStatus) => {
  if (orderStatus) {
    return ORDER_STATUS_WITH_NOTES_ALLOWED_ON_MAP_PAGE.includes(orderStatus);
  }
};

export const setFlow = (orgId, profileData, orderStatus) => {
  if (ORDER_STATUS_ALLOWED_FOR_ORDER_FLOW_PAGE.includes(orderStatus)) {
    if (orgId && profileData) {
      const userOrgId = profileData.organization_id;
      const srId = profileData.sr_user_type_id;

      let temp = [];
      if (orgId === userOrgId) {
        temp = ['Comment', 'Delete', 'Update Status', 'View'];
        if (orderStatus !== 4 && orderStatus !== 7) temp.push('Add');
      } else {
        if (srId === 1) temp = ['View', 'Comment'];
        else if (srId === 2) temp = ['View'];
        else temp = [];
      }
      return temp;
    }
  }
  return [];
};

export const round = (value, exp) => {
  if (typeof exp === 'undefined' || +exp === 0) return Math.round(value);

  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) return NaN;

  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? +value[1] + exp : exp)));

  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp));
};


export const include = (array) => {
  if (!array || array.length === 0) {
    return false;
  }
  let found = false;
  for (let i = 0; i < array.length; i++) {
    if (array[i].ti === 'Other') {
      found = true;
      break;
    }
  }
  return found;
};

export const sortJSON = (arr, key, asc = true) => {
  return arr.sort((a, b) => {
    let x = a[key];
    let y = b[key];
    if (asc) {
      return x < y ? -1 : x > y ? 1 : 0;
    } else {
      return x > y ? -1 : x < y ? 1 : 0;
    }
  });
};

export const getNewMapCenterCoordinates = (defaultLong, defaultLat, olmap, offsetY = 0, offsetX = 0) => {

  const mapResolutionInPixels = olmap.getSize();

  const currentViewportCoordinates = olmap.getView().calculateExtent(olmap.getSize());
  const bottomLeft = getBottomLeft(currentViewportCoordinates);
  const topRight = getTopRight(currentViewportCoordinates);
  const bottomRight = getBottomRight(currentViewportCoordinates);
  const topLeft = getTopLeft(currentViewportCoordinates);

  const totalX = Math.abs(bottomRight[0] - bottomLeft[0]);
  const totalY = Math.abs(bottomRight[1] - topRight[1]);

  const perPixelDistOnMap = [totalX / mapResolutionInPixels[0], totalY / mapResolutionInPixels[1]];
  const distanceCardBuffer = [perPixelDistOnMap[0] * 180, perPixelDistOnMap[1] * 80];

  return [defaultLong + distanceCardBuffer[0], defaultLat - distanceCardBuffer[0]];
};

export const getCharPosition = (string, subString, index = 1) => {
  return string.split(subString, index).join(subString).length;
}

export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
}

export const removeLSepCharacter = (str) => {
  return str.replace(/[\u2028]/g, '<br>');
}

export const convertTohyphenSeparatedDateFormat = (date) => {
  return date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
}

export const openUrl = (url) => {
  return window.open(url, '_blank');
}

/**Get First char of a string */
export const getName = (n) => {
  if (!n) {
    return;
  }
  n = n.trim();

  if (n.length === 1) {
    return n;
  }
  return n[0].toUpperCase();
};

export const removeNullOrUndefinedFromArray = (data) => {
  if (!data.length) return [];

  return data.filter(value => value !== undefined && value !== null);
}

export const checkIfMapPage = () => {
  const currentURL = window.location.href;

  const stringsToCheck = ['/createOrder', '/project', '/properties', '/share_id'];

  let containsString = false;

  // Loop through the array of strings to check
  for (let i = 0; i < stringsToCheck.length; i++) {
    if (currentURL.indexOf(stringsToCheck[i]) !== -1) {
      containsString = true;
      break; // Exit the loop if a match is found
    }
  }

  return containsString;

}

export const checkIfBulkOrder = () => {
  const currentURL = window.location.href;
  return currentURL.includes('properties');
}

export const checkIfShareMapPage = () => {
  const currentURL = window.location.href;
  return currentURL.includes(SHARE_MAP_PAGE);
}

/** Round off the difference of few hours to 1 day  */
export const differenceInDaysRounded = (
  dateLeft,
  dateRight
) => {
  return differenceInDays(dateLeft, dateRight) + 1;
};

let loadingCursor = null;

const handleCursorMouseMode =  (e)  => {
  loadingCursor.style.left = e.clientX + 'px';
  loadingCursor.style.top = e.clientY + 'px';
}

export const showLoadingCursor = () => {
  if (!loadingCursor) {
    loadingCursor = document.createElement('div');
    loadingCursor.id = 'loading-cursor';
    loadingCursor.className = 'loading-cursor';
  }

  const handleMouseOver = (evt) => {
    loadingCursor.style.left = e.clientX + 'px';
    loadingCursor.style.top = e.clientY + 'px';
  };

  document.addEventListener('mouseover', handleMouseOver);
  document.removeEventListener('mouseover', handleMouseOver)


  document.body.appendChild(loadingCursor);
  document.body.classList.add('no-cursor');

  document.addEventListener('mousemove', handleCursorMouseMode);
}

export const hideLoadingCursor = () => {
  const loadingCursor = document.getElementById('loading-cursor')
  if (!loadingCursor) return;
  document.body.classList.remove('no-cursor');
  document.body.removeChild(loadingCursor);

  document.removeEventListener('mousemove', handleCursorMouseMode);
}

export const pxToVhSR = (px, designHeight = 820) => {
  // calc(100vh * (16 / 820))
  return `${(px /designHeight )* 100}vh`;
}

export const pxToVwSR = (px, designWidth = 1440) => {
  // calc(100vh * (16 / 1440))
  return `${(px /designWidth )* 100}vh`;
}

export const formatWithOneDecimal = (value) => {
  return parseFloat(value.toString()).toLocaleString(undefined, { maximumFractionDigits: 1 });
}
