import { useSelector } from 'react-redux';
import { useFeatureStyle } from '../../../../jotai/atoms/features';
import { NumericId } from '../../../common/types';
import { updateFeatureStyleCatalogue } from '../../api';
import {
  FeatureStyleCatalogueResponse,
  LayerStyle,
} from '../../types/layer.types';
import { syncCustomDeletedStyle } from './useFetchFeatureStyleCatalogue';
import { useEffect } from 'react';

const useUpdateFeatureStyle = () => {
  const [catalogue, setFeatureStyleCatalogue] = useFeatureStyle();
  const layers = useSelector(
    (state) => state.order.layerList
  );

  const updateStyle = async (
    featureId: NumericId,
    styleId: NumericId,
    styleObj: LayerStyle
  ) => {
    try {
      const response: FeatureStyleCatalogueResponse =
        await updateFeatureStyleCatalogue(featureId, styleId, styleObj);
        const foundItem = response.find(item => item.featureId === featureId);

        if (!foundItem) {
         // Update the catalogue state with the new data
          syncCustomDeletedStyle(response, layers, setFeatureStyleCatalogue);
          return 
          
        }
        const updatedResponse = [
          ...catalogue.map((oldItem) => {
            const newItem = response.find(
              (newItem) => newItem.featureId === oldItem.featureId
            );
            return newItem ? { ...oldItem, ...newItem } : oldItem;
          }),
          ...response.filter(
            (newItem) =>
              !catalogue.some(
                (oldItem) => oldItem.featureId === newItem.featureId
              )
          ), // Add items from the response that are not in the catalogue
        ];

        setFeatureStyleCatalogue(updatedResponse);
        

        // Handle the case when the featureId does not exist in the response
    } catch (error) {
      console.error('Error updating feature style:', error);
    }
  };
  useEffect(() => {
    if (catalogue.length) {
      setFeatureStyleCatalogue(catalogue) 
    }
  }, [catalogue, setFeatureStyleCatalogue]);

  return { updateStyle };
};

export default useUpdateFeatureStyle;
